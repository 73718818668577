.bottom-bar {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 25px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}