@use '~@liveperson-design-system/tokens/scss/typography';

.top-bar {
  text-align: center;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
}

.top-bar h1 {
  margin: 0;
  padding: 2px;
  @include typography.header(1);
}