.main-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.main-form lp-input-label {
  width: 168px;
}

.main-form lp-text-input {
  width: 168px;
}

.main-form lp-input-helper {
  width: 168px;
}